/* core */

/* utils */

.u-responsive-ratio {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.u-responsive-ratio--wide {
    padding-bottom: 60%;
  }

.u-responsive-ratio--ultra-wide,
  .u-responsive-ratio--r20by9 {
    padding-bottom: 45%;
  }

.u-responsive-ratio--r1by1,
  .u-responsive-ratio--square {
    padding-bottom: 100%;
  }

.u-responsive-ratio--r16by9 {
    padding-bottom: 56.25%;
  }

.u-responsive-ratio--43,
  .u-responsive-ratio--r4by3 {
    padding-bottom: 75%;
  }

.u-responsive-ratio--rPanovision70 {
    padding-bottom: 36.101083%;
  }

.u-responsive-ratio > img,
  .u-responsive-ratio .ImagePlaceholder {
    width: 100% !important;
    height: 100% !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
  }

.u-responsive-fit {
  position: relative;
  overflow: hidden;
}

.u-responsive-fit > img,
  .u-responsive-fit .ImagePlaceholder {
    width: 100% !important;
    height: 100% !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
  }

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  -webkit-transition: opacity 300ms;
  transition: opacity 300ms;
}

/* player */

.Player {
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(26, 24, 24);
  color: #ffffff;
  -webkit-box-shadow: 0 0 16px 0 rgba(30, 30, 30, .3);
          box-shadow: 0 0 16px 0 rgba(30, 30, 30, .3);
  z-index: 90;
}

.Player__audio {
    width: 100%;
  }

.Player__container {
    padding: 8px;
    padding: 0.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-top: 1px solid rgba(30, 30, 30, .2);
    z-index: 100;
    position: relative;
    text-shadow: 0 1px 4px rgba(30, 30, 30, .5);
  }

.Player__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: center;
        align-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    z-index: 1;
  }

.Player__img-container {
    position: absolute;
    overflow: hidden;
    height: 100%;
    width: 100%;
  }

.Player__img-back {
      position: absolute;
      left: 0;
      right: 0;
      top: -40vw;
      width: 100%;
     filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="30" /><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="1.3935000000000002 -0.35750000000000004 -0.03599999999999999 0 0 -0.10650000000000001 1.1575 -0.03599999999999999 0 0 -0.10650000000000001 -0.35750000000000004 1.4640000000000002 0 0 0 0 0 1 0" /><feComponentTransfer color-interpolation-filters="sRGB"><feFuncR type="linear" slope="1.2" /><feFuncG type="linear" slope="1.2" /><feFuncB type="linear" slope="1.2" /></feComponentTransfer></filter></svg>#filter');
     -webkit-filter: blur(30px) saturate(1.5) brightness(1.2);
             filter: blur(30px) saturate(1.5) brightness(1.2);
     opacity: 0.8;
   }

.Player__section {
    -webkit-box-flex: 1;
        -ms-flex: 1 1;
            flex: 1 1;
  }

.Player__controls {
    text-align: center;
  }

.Player__show-cover {
    width: 56px;
    height: 56px;
    -webkit-box-shadow: 0 2px 5px rgba(30, 30, 30, .2);
            box-shadow: 0 2px 5px rgba(30, 30, 30, .2);
  }

.Player__show-cover > img {
      width: 100%;
      height: 100%;
    }

.Player__show-name {
    font-weight: 700;
    color: #ffffff;
    text-decoration: none;
    margin-right: 8px;
    margin-right: 0.5rem;
  }

.Player__streams {
    text-align: right;
  }

@media (max-width: 768px) {

.Player__streams {
      display: none
  }
    }

.Player__button {
    display: block;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

.Player__show-details {
    padding-left: 16px;
    padding-left: 1rem;
  }

.PlayPauseButton {
  margin: auto;
  border: 1px solid white;
  background: transparent;
  padding: 10px;
  border-radius: 50%;
  display: block;
}

.PlayPauseButton svg {
    display: block;
  }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.LoadingIcon {
  -webkit-animation: spin infinite linear 1s;
          animation: spin infinite linear 1s;
}

.StreamSwitcher__selector {
    background: transparent;
    color: #fff;
    padding: 10px;
    border: 0;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
  }

.StreamSwitcher__selector__indicator {
      width: 20px;
      height: 20px;
      position: relative;
      top: 3px;
      margin-right: 3px;
      fill: #fff;
      -webkit-transition: -webkit-transform .25s;
      transition: -webkit-transform .25s;
      transition: transform .25s;
      transition: transform .25s, -webkit-transform .25s;
    }

.StreamSwitcher__options {
    position: fixed;
    right: 5px;
    bottom: 60px;
    z-index: 100;
    background: #fff;
    border: 3px solid #fff;
    border-radius: 5px;
    -webkit-transition: -webkit-transform .25s ease-out;
    transition: -webkit-transform .25s ease-out;
    transition: transform .25s ease-out;
    transition: transform .25s ease-out, -webkit-transform .25s ease-out;
    -webkit-transform-origin: bottom;
            transform-origin: bottom;
  }

.StreamSwitcher__options__open {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
    }

.StreamSwitcher__options__closed {
      -webkit-transform: scaleY(0);
              transform: scaleY(0);
    }

.StreamSwitcher__stream {
    padding: 10px;
    width: 400px;
    background: #fff;
    color: #111;
    text-align: left;
    text-shadow: none;
    position: relative;
    border-radius: 5px;
    cursor: pointer;

    -webkit-transition: color .25s, background-color .25s;

    transition: color .25s, background-color .25s;
  }

.StreamSwitcher__stream__offline {
      color: #7f7f7f;
    }

.StreamSwitcher__stream__title {
      font-weight: 700;
    }

.StreamSwitcher__stream__meta {
      color: #666666;
      -webkit-transition: color .25s;
      transition: color .25s;
    }

.StreamSwitcher__stream:hover {
      background-color: rgb(144, 135, 220);
      color: #fff;
    }

.StreamSwitcher__stream:hover .StreamSwitcher__stream__meta {
      color: #bababa;
    }

/* components */

.GlobalAnnouncement {
  background-color: #2e9a7f;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #fff;
  display: none;
  font-size: 22.4px;
  font-size: 1.4rem;
  font-weight: 600;
  padding: 8px;
  padding: 0.5rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  width: 100%;
  z-index: 110;
}

.GlobalAnnouncement--visible {
    display: block;
  }

.Core {
  background: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  font-family: -apple-system, '.SFNSText-Regular', 'San Francisco', 'Roboto',
    'Segoe UI', 'Helvetica Neue', 'Lucida Grande', sans-serif;
  font-weight: 500;
  margin: 0;
  min-height: 100vh;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(26, 24, 24);
}

.Core__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

.Container {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-left: 1rem;
  padding-right: 16px;
  padding-right: 1rem;
  position: relative;
  width: 100%;
  clear: both;
}

@media (min-width: 768px) {

.Container {
    padding-left: 3rem;
    padding-right: 3rem
}
  }

.HeaderContainer {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-left: 1rem;
  padding-right: 16px;
  padding-right: 1rem;
  position: relative;
  width: 100%;
  clear: both;
}

.ContainerProse {
  margin: 0 auto;
  padding: 0 16px;
  padding: 0 1rem;
  font-size: 19.2px;
  font-size: 1.2rem;
  line-height: 1.6;
  max-width: 660px;
}

.Content__title {
    text-align: center;
  }

.Content__body {
    font-size: 17.6px;
    font-size: 1.1rem;
    -webkit-font-smoothing: antialiased;
    line-height: 1.6em;
    margin: 0 auto;
    max-width: 736px;
    max-width: 46rem;
  }

.Header {
  background: #f6f6f6;
  z-index: 0;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 75px;
}

@media (min-width: 768px) {

.Header {
    width: 180px;
    background: rgb(42, 37, 38);
    right: auto;
    height: auto;
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}
  }

.Header__container {
  }

.Header__menu {
    left: -60px;
    padding-top: 6.4px;
    padding-top: 0.4rem;
    float: left;
    position: relative;
  }

@media (min-width: 768px) {

.Header__menu {
      left: 0;
      float: none
  }
    }

.Header__logo {
    display: block;
    position: relative;
    left: 0;
    top: 0;
    margin: 2.5px 0;
    float: left;
  }

.Header__logo > a {
      display: block;
    }

@media (min-width: 768px) {

.Header__logo {
      float: none;
      margin: 20px -0.9rem
  }
    }

.HeaderNav {
  font-size: 17.6px;
  font-size: 1.1rem;
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding-left: 0;
  padding-right: 0;
  font-weight: 800;
}

.HeaderNav__item {
    padding-left: 0.2em;
    padding-right: 0.2em;
    text-transform: uppercase;
  }

.HeaderNav__item:last-child::after {
      display: none;
    }

@media (min-width: 768px) {

.HeaderNav__item {
      float: left
  }

      .HeaderNav__item:after {
        content: '/';
        display: inline;
      }
    }

.HeaderNav__link {
    color: #000;
    font-weight: 600;
    padding-left: 0.2em;
    padding-right: 0.2em;
    text-decoration: none;
  }

.NowAndNext {
  font-size: 16px;
  font-size: 1rem;
  background-color: #f04c37;
  color: #fff;
  cursor: pointer;
  display: block;
  height: 100%;
  padding-left: 16px;
  padding-left: 1rem;
  padding-right: 16px;
  padding-right: 1rem;
  position: relative;
  text-decoration: none;
  border: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  margin: 0;
  text-align: left;
}

.NowAndNext__heading {
    font-weight: 600;
    text-transform: uppercase;
  }

.NowAndNext__icon {
    width: 24px;
    margin-right: 16px;
    margin-right: 1rem;
    float: left;
  }

.NowAndNextRoot {
  display: block;
}

.Shows__SortHeader {
  margin: 0;
}

.Shows__SortSelect {
  cursor: pointer;
  -webkit-transition: color .25s;
  transition: color .25s;
}

.Shows__SortSelect__active {
    border-bottom: 3px solid rgb(144, 135, 220);
  }

.Shows__SortSelect__inactive {
    color: #999;
  }

.Shows__SortSelect:hover {
    color: #000;
  }

.ShowHeader {
  position: relative;
  -webkit-box-shadow: 0 0 5px rgba(40, 40, 40, .2);
          box-shadow: 0 0 5px rgba(40, 40, 40, .2);
  -webkit-transition: background ease 300ms;
  transition: background ease 300ms;
}

.ShowHeader--has-cover {
    padding-bottom: 100px;
    padding-top: 200px;
  }

.ShowHeader--loading {
    background-color: #f4f4f4;
  }

.ShowHeader__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

.ShowHeader__info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    text-align: center;
  }

@media (min-width: 960px) {

.ShowHeader__info {
      text-align: left
  }
    }

.ShowHeader__cover {
    width: 200px;
    height: 200px;
    -webkit-box-shadow: 0 0 5px 1px rgba(50, 50, 50, .2);
            box-shadow: 0 0 5px 1px rgba(50, 50, 50, .2);
    position: relative;
    background: #ffffff;
    margin: 0 auto;
    bottom: 0;
  }

@media (min-width: 960px) {

.ShowHeader__cover {
      bottom: -60px;
      margin: 0 1rem
  }
    }

.ShowHeader__cover > img {
      width: 100%;
      height: 100%;
    }

.ShowHeader__cover-image {
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    width: 100%;
  }

.ShowHeader__cover-gradient {
    bottom: 0;
    height: 250px;
    left: 0;
    position: absolute;
    right: 0;
  }

.ShowHeader--tone-dark {
    color: rgba(0, 0, 0, 1);
  }

.ShowHeader--tone-dark a {
      color: rgba(0, 0, 0, 1);
    }

.ShowHeader--tone-light {
    color: rgba(255, 255, 255, 1);
    text-shadow: 0 0 3px rgba(40, 40, 40, .6);
  }

.ShowHeader--tone-light a {
      color: rgba(255, 255, 255, 1);
    }

.ShowHeader__show-title {
    margin-bottom: 0;
    margin-top: 0;
    text-align: center;
    font-size: 35.2px;
    font-size: 2.2rem;
    padding-bottom: 16px;
    padding-bottom: 1rem;
  }

@media (min-width: 960px) {

.ShowHeader__show-title {
      text-align: left
  }
    }

.ShowHeader__show-title > a {
      text-decoration: none;
    }

.ShowHeader__show-title--logo a > img {
      max-width: 300px;
      max-height: 60px;
    }

.ShowHeader__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

@media (min-width: 960px) {

.ShowHeader__container {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row
  }
    }

.ShowHeader__details {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    padding-bottom: 16px;
    padding-bottom: 1rem;
    padding-left: 16px;
    padding-left: 1rem;
  }

.ShowHeader--has-cover .ShowHeader__container {
    text-shadow: 1px 1px 2px rgba(30, 30, 30, .3);
  }

.ShowHeader__short-description {
    font-size: 20.8px;
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 8px;
    margin-bottom: 0.5rem;
    padding-top: 4px;
    padding-top: 0.25rem;
  }

@media (min-width: 960px) {

.ShowHeader__short-description {
      padding-right: 20%
  }
    }

.ShowHeader__short-description p {
      margin: 0;
    }

.ShowHeader__schedule-times {
    font-size: 20.8px;
    font-size: 1.3rem;
    font-weight: 500;
    display: block;
    padding-bottom: 16px;
    padding-bottom: 1rem;
  }

.ShowHeader__members {
    font-style: italic;
    font-size: 19.2px;
    font-size: 1.2rem;
  }

.ShowContent {
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding: 22.4px 0;
  padding: 1.4rem 0;
  z-index: 10;
}

.ShowContent__content {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    min-width: 0;
  }

.ShowContent__content .block-image {
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      padding: 16px;
      padding: 1rem;
    }

.ShowContent__content .block-image > img {
        display: block;
        height: auto;
        margin: 0 auto;
        max-width: 100%;
        width: auto;
      }

.ShowNav {
  font-size: 19.2px;
  font-size: 1.2rem;
  margin: 0;
  padding: 0;
}

.ShowNav__list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

.ShowNav__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

.ShowNav__main {
    -webkit-box-flex: 1;
        -ms-flex: auto;
            flex: auto;
  }

.ShowNav__item {
    display: inline-block;
    margin-left: 16px;
    margin-left: 1rem;
  }

.ShowNav__item:first-child {
      margin-left: 0;
    }

.ShowNav__item > a {
      display: block;
      padding: 3.2px 0;
      padding: 0.2rem 0;
      text-decoration: none;
    }

.ShowNav__item--active > a {
        font-weight: 600;
      }

.ShowNav__item--active-child {
      font-weight: 600;
    }

.ShowNav__item-icon {
    display: inline-block;
    font-size: 19.2px;
    font-size: 1.2rem;
    width: 24px;
    width: 1.5rem;
  }

.ShowNav__social-facebook > a {
      color: facebook-color;
    }

.ShowNav__social-twitter > a {
      color: twitter-color;
    }

.ShowNav__social-youtube > a {
      color: youtube-color;
    }

.ShowNav__social-mixcloud > a {
      color: mixcloud-color;
    }

.ShowNav__social-soundcloud > a {
      color: soundcloud-color;
    }

.FooterNav__list {
    list-style: none;
    margin: 0 0 16px;
    margin: 0 0 1rem;
    padding: 0;
  }

.FooterNav__item > a {
      color: #fff;
      text-decoration: none;
    }

.TeamList {
  list-style: none;
  margin: 0;
  padding: 0;
}

.TeamList__item {
    margin-bottom: 28.8px;
    margin-bottom: 1.8rem;
  }

.TeamMember {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.TeamMember__name {
    font-size: 32px;
    font-size: 2rem;
    margin-bottom: 6.4px;
    margin-bottom: 0.4rem;
  }

.TeamMember__image {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }

.TeamMember__info {
    -webkit-box-flex: 1;
        -ms-flex: auto;
            flex: auto;
  }

.TeamMember__bio {
    max-width: 600px;
    font-size: 19.2px;
    font-size: 1.2rem;
  }

.TeamMember__job-title {
    font-size: 23.2px;
    font-size: 1.45rem;
    font-weight: 300;
    margin-bottom: 12.8px;
    margin-bottom: 0.8rem;
  }

.TeamMember:nth-child(odd) .TeamMember__info {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
  padding-right: 32px;
  padding-right: 2rem;
}

.TeamMember:nth-child(even) .TeamMember__info {
  -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
          order: 3;
  padding-left: 32px;
  padding-left: 2rem;
}

.Slider__frame {
    font-size: 0;
    line-height: 0;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
  }

.Slider__slides {
    display: inline-block;
    margin: 0;
    padding: 0;
  }

.Slider li {
    display: inline-block;
    font-size: 15px;
    line-height: 30px;
    position: relative;
    white-space: normal;
  }

.InlinePlayer {
  -webkit-box-shadow: 0 1px 2px 0 rgba(20, 20, 20, .1);
          box-shadow: 0 1px 2px 0 rgba(20, 20, 20, .1);
  border-top: 1px solid #eee;
  background: #fff;
  padding: 4px 0;
  padding: 0.25rem 0;
}

.InlinePlayer > .Container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

.InlinePlayer__play {
    display: inline-block;
  }

.FeatureSlider__slides {
    width: 100%;
  }

.FeatureSlider__slide {
    height: 280px;
    width: 100%;
  }

.FeatureSlider__navigation {
    position: relative;
    top: 120px;
  }

.FeatureSlider__prev {
    left: 0;
    position: absolute;
    z-index: 95;
  }

.FeatureSlider__next {
    position: absolute;
    right: 0;
    z-index: 95;
  }

@-webkit-keyframes three-sixty {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes three-sixty {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.Spinner {
  -webkit-animation: three-sixty 1.4s infinite linear;
          animation: three-sixty 1.4s infinite linear;
  border-radius: 50%;
  font-size: 6px;
  height: 11em;
  margin: 32px auto;
  margin: 2rem auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  width: 11em;
}

.Spinner:before {
    background: rgb(144, 135, 220);
    border-radius: 100% 0 0 0;
    content: '';
    height: 50%;
    left: 0;
    position: absolute;
    top: 0;
    width: 50%;
  }

.Spinner:after {
    background: #f1eef6;
    border-radius: 50%;
    bottom: 0;
    content: '';
    height: 75%;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 75%;
  }

.Page {
  background-color: #f6f1f1;
  position: relative;
  margin-top: 75px;
  min-height: calc(100vh - 75px);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: 0 0 28px 0 rgba(30, 30, 30, .3);
          box-shadow: 0 0 28px 0 rgba(30, 30, 30, .3);
  padding-bottom: 100px;
  overflow: hidden;
}

@media (min-width: 768px) {

.Page {
    margin-top: 0;
    margin-left: 180px;
    min-height: 100vh
}
  }

.Page__heading {
    margin: 0;
    padding-top: 12px;
    padding-top: 0.75rem;
    display: inline-block;
  }

@media (min-width: 768px) {

.Page__heading {
      margin-bottom: 1.25rem
  }
    }

@-webkit-keyframes BroadcastingFade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes BroadcastingFade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.Broadcasting {
  width: 100%;
}

.Broadcasting__left-end,
  .Broadcasting__right-end {
    animation: BroadcastingFade 1.2s infinite reverse 750ms;
  }

.Broadcasting__left-center,
  .Broadcasting__right-center {
    animation: BroadcastingFade 1.2s infinite reverse 500ms;
  }

.Broadcasting__left-start,
  .Broadcasting__right-start {
    animation: BroadcastingFade 1.2s infinite reverse 250ms;
  }

.ApplicationForm .FormHeader {
		text-align: center;
	}

.ApplicationForm .FormHeader h1 {
			font-weight: 600;
			font-size: 48px;
			margin: 10px 0 5px;
			text-transform: uppercase;
		}

.ApplicationForm .FormHeader p {
      display: inline-block;
			margin-bottom: 0;
		}

@media (min-width: 960px) {

.ApplicationForm .FormHeader p {
        max-width: 50%
		}
      }

.StyledInput {
	margin: 50px 0;
}

.StyledInput > label {
		display: inline-block;
		font-size: 36px;
		font-weight: 600;
	}

.StyledInput .meta {
		color: #888;
		font-size: 16px;
	}

.StyledInput > input, .StyledInput > textarea, .StyledInput > select {
		display: block;
		padding: 20px 10px 10px 5px;
		width: 100%;
		background: none;
		border: 0;
		-webkit-box-shadow: none;
		        box-shadow: none;
		resize: none;
		font-size: 20px;
		border-bottom: 4px solid rgb(144, 135, 220);
		font-family: inherit;
		-webkit-transition: border .25s;
		transition: border .25s;
		-webkit-box-sizing: border-box;
		        box-sizing: border-box;
	}

@media (min-width: 960px) {

.StyledInput > input, .StyledInput > textarea, .StyledInput > select {
			width: 50%
	}
		}

.StyledInput > input:valid, .StyledInput > textarea:valid, .StyledInput > select:valid {
		border-bottom: 4px solid #41c001;
	}

.SubmitInput > input[type=submit] {
  display: block;
  padding: 20px 10px;
  width: 100%;
  background: rgb(144, 135, 220);
  color: #fff;
  border: 0;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
}

.SubmitInput > input[type=submit]:hover {
    background-color: rgb(103, 96, 175);
  }

.BoolInput > .Flipper {
    display: inline-block;
    margin: 0 10px 10px 5px;
    padding: 20px;
    width: 50px;
    height: 50px;
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    -webkit-transition: background .25s;
    transition: background .25s;
  }

.BoolInput > input[type=checkbox] {
    opacity: 0;
    width: 0;
    height: 0;
  }

.BoolInput > input[type=checkbox]:checked + .Flipper {
      background: url('data:image/svg+xml;utf8,<svg width="1700" height="1536" viewBox="0 100 1700 1536" fill="rgb(65, 192, 1)" xmlns="http://www.w3.org/2000/svg"><path d="M1671 566c0 25-10 50-28 68l-724 724-136 136c-18 18-43 28-68 28s-50-10-68-28l-136-136-362-362c-18-18-28-43-28-68s10-50 28-68l136-136c18-18 43-28 68-28s50 10 68 28l294 295 656-657c18-18 43-28 68-28s50 10 68 28l136 136c18 18 28 43 28 68z"/></svg>') 0%/85px no-repeat, #fff;
    }

.BoolInput > input[type=checkbox]:focus + .Flipper, .BoolInput > input[type=checkbox] .Flipper:focus {
      border-color: #2e9a7f;
    }

.EmojiInput, .ColorInput {
  margin-bottom: 5px;
}

.EmojiInput > input[type=button], .ColorInput > input[type=button] {
    display: inline-block;
    width: auto;
    border: 1px solid #888;
    border-bottom: 4px solid #41c001;
    border-radius: 5px 5px 0 0;
    padding: 5px 10px;
    margin-top: 20px;
    font-size: 36px;
  }

.EmojiInput .emoji-mart, .ColorInput .emoji-mart {
    position: absolute;
  }

.ColorInput > input[type=button] {
    width: 200px;
  }

.ColorInput .twitter-picker {
    position: absolute !important;
  }

.MainNavigation {
  font-size: 19.2px;
  font-size: 1.2rem;
  position: relative;
  font-weight: 600;
}

.MainNavigation__list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

.MainNavigation--mobile {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    background-color: rgb(26, 24, 24);
    padding: 4.8px 8px;
    padding: 0.3rem 0.5rem;
    font-size: 19.2px;
    font-size: 1.2rem;
    -webkit-box-shadow: 2px 0 5px rgba(30, 30, 30, .6);
            box-shadow: 2px 0 5px rgba(30, 30, 30, .6);
  }

.MainNavigation--mobile .MainNavigation__list {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }

.MainNavigation--mobile .MainNavigation__item {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
              flex: 0 0 auto;
    }

.MainNavigation--mobile .MainNavigation__item > a {
        color: #ffffff;
      }

@media (min-width: 768px) {

.MainNavigation--mobile {
      display: none
  }
    }

.MainNavigation--desktop {
    display: none;
  }

.MainNavigation--desktop .MainNavigation__item {
      display: block;
      margin-bottom: 16px;
      margin-bottom: 1rem;
    }

.MainNavigation--desktop .MainNavigation__item > a:hover,
      .MainNavigation--desktop .MainNavigation__item > a.active {
        border-bottom: 3px solid rgb(144, 135, 220);
      }

.MainNavigation--desktop .MainNavigation__break {
      display: block;
      margin: 16px 0;
      margin: 1rem 0;
      color: #888;
    }

@media (min-width: 768px) {

.MainNavigation--desktop {
      display: block
  }
    }

.MainNavigation__item > a {
    margin: 0 12.8px;
    margin: 0 0.8rem;
    text-decoration: none;
    color: #ffffff;
  }

@media (min-width: 768px) {

.MainNavigation__item > a {
      margin: 0
  }
    }

.NewsItem {
  font-family: Helvetica;
  height: 300px;
  width: 300px;
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .19);
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .19);
}

.NewsItem--featured {
    height: 300px;
    width: 500px;
  }

.TileImage {
  display: inline-block;
  width: 100%;
  height: 230px;
  vertical-align: top;
  overflow: hidden;
}

.TileDetails {
  display: inline-grid;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 10px;
  max-width: 100%;
  height: 70px;
  max-height: 90px;
}

.NewsItem__title {
  display: table;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  grid-column-start: 1;
}

.NewsItem__titletext {
  display: inline;
}

.NewsItem__description {
  padding-top: 3px;
  display: inline;
  grid-row-start: 2;
  max-height: 3em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.NewsItem__date {
  display: table;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  grid-column-start: 2;
  float: right;
}

.NewsItem__image {
  display: block;
  margin: 0 auto;
  height: auto;
  width: auto;
  max-width: 130%;
  max-height: 130%;
}

@-webkit-keyframes scroll-left {
  0% {
    left: -1000px;
  }

  100% {
    left: 0;
  }
}

@keyframes scroll-left {
  0% {
    left: -1000px;
  }

  100% {
    left: 0;
  }
}

@-webkit-keyframes scroll-right {
  0% {
    left: 0;
  }

  100% {
    left: -1000px;
  }
}

@keyframes scroll-right {
  0% {
    left: 0;
  }

  100% {
    left: -1000px;
  }
}

.MembersGallery {
  overflow: hidden;
  height: 345px;
  position: relative;
}

.MembersGallery__title {
    color: rgb(176, 39, 39);
    font-size: 8vmax;
    text-transform: uppercase;
    font-weight: bold;
    position: relative;
    line-height: 315px;
    text-align: center;
    z-index: 10;
    margin: 0;
    text-shadow: 0 0 32px rgba(255, 255, 255, 1);
  }

.MembersGallery__background {
    position: absolute;
    top: 0;
    left: 0;
  }

.MembersGallery__row {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    will-change: left;
  }

.MembersGallery__row--left {
    -webkit-animation: scroll-left 90s linear infinite alternate;
            animation: scroll-left 90s linear infinite alternate;
  }

.MembersGallery__row--left-faster {
    -webkit-animation: scroll-left 60s linear infinite alternate;
            animation: scroll-left 60s linear infinite alternate;
  }

.MembersGallery__row--right {
    -webkit-animation: scroll-right 90s linear infinite alternate;
            animation: scroll-right 90s linear infinite alternate;
  }

.MembersGallery__image {
    width: 2800px;
    display: block;
    -webkit-box-flex: 0;
        -ms-flex: none;
            flex: none;
    height: 115px;
  }

.ErrorPage {
  text-align: center;
}

.ErrorPage__figure {
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
  }

.ErrorPage__image {
    width: 100%;
    display: block;
  }

.ErrorPage__caption {
    font-family: Georgia, serif;
    font-style: italic;
    text-align: center;
    color: grey;
  }

.ErrorPage > .caption {
    color: grey;
    font-style: italic;
  }

/* schedule */

.Schedule {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Schedule__days {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto;
  }

.Schedule__scroll {
    width: 3600px;
  }

.Schedule__scroll-container {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 20.8px;
    padding-bottom: 1.3rem;
  }

.ScheduleSingleContainer {
  min-height: 132px;
}

.WeekSelector {
  margin-left: 10px;
}

.WeekSelector__inline, .WeekSelector > * {
    display: block;
    margin: 10px 0;
    vertical-align: bottom;
    vertical-align: -moz-middle-with-baseline;
    vertical-align: -webkit-baseline-middle;
  }

@media (min-width: 768px) {

.WeekSelector__inline, .WeekSelector > * {
      margin: 0 10px;
      display: inline-block
  }
    }

.WeekSelector__Week {
    -webkit-transition: color .25s, fill .25s;
    transition: color .25s, fill .25s;
    cursor: pointer;
  }

.WeekSelector__Week__inactive {
      color: #999;
      fill: #999;
    }

.WeekSelector__Week:hover {
      color: #000;
      fill: #000;
    }

.WeekSelector h2 {
    margin: 0;
    line-height: 0.8em;
  }

.WeekSelector svg {
    width: 18px;
    height: 18px;
  }

.ScheduleRow:after {
    content: '';
    display: table;
    clear: both;
  }

.ScheduleRow__empty {
  height: 86px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 10px 0;
  text-align: center;
}

.ScheduleDayColumn {
  padding-top: 25px;
}

.ScheduleDayColumn__day {
    border-right: 1px solid #fff;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    display: block;
    height: 86px;
    padding: 8px;
    padding: 0.5rem;
    position: relative;
    color: #848484;
  }

.ScheduleSlot {
  border: 1px solid #fff;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: block;
  float: left;
  height: 86px;
  opacity: 1;
  position: relative;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.ScheduleSlot--tone-light {
    color: rgba(0, 0, 0, 1);
  }

.ScheduleSlot--active {
    opacity: 1;
  }

.ScheduleSlot__inner {
    background: #e2e2e2;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    color: rgba(0, 0, 0, 1);
    display: block;
    height: 100%;
    padding: 4px 8px;
    padding: 0.25rem 0.5rem;
    text-decoration: none;
    width: 100%;
  }

.ScheduleSlot__show-name {
    font-weight: 700;
    font-size: 15.2px;
    font-size: 0.95rem;
  }

.ScheduleSlot__time {
    font-size: 0.8em;
    margin-bottom: 3.2px;
    margin-bottom: 0.2rem;
    font-weight: 400;
  }

.ScheduleSlot__time-item--continuation {
      opacity: 0.6;
    }

.ScheduleSlot--on-air {
    border: 0;
    position: relative;
    z-index: 90;
    -webkit-transform: scale(1.1) translateZ(0);
            transform: scale(1.1) translateZ(0);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
  }

.ScheduleSlot--on-air .ScheduleSlot__inner {
  font-size: 0.9em;
}

.ScheduleSlot--tone-light .ScheduleSlot__inner {
  color: rgba(255, 255, 255, 1);
}

.ScheduleTimeline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 25px;
  position: relative;
}

.ScheduleTimeline__hour {
    border-left: 1px solid #d1d1d1;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    color: #848484;
    padding-left: 8px;
    padding-left: 0.5rem;
    font-weight: 700;
  }

.ScheduleTimeline__bookmark {
    border-left: 1px solid #ff3d35;
    height: 20px;
    position: absolute;
  }

@-webkit-keyframes pulsate {
  0% {
    opacity: 0.2;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}

@keyframes pulsate {
  0% {
    opacity: 0.2;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}

.OnAirBadge {
  background: #df1c1d;
  border-radius: 2px;
  color: #fff;
  display: inline-block;
  font-weight: 600;
  margin-left: 9.6px;
  margin-left: 0.6rem;
  padding-left: 4.8px;
  padding-left: 0.3rem;
  padding-right: 4.8px;
  padding-right: 0.3rem;
  text-transform: uppercase;
}

.OnAirBadge:after {
    -webkit-animation: pulsate 900ms ease-out infinite;
            animation: pulsate 900ms ease-out infinite;
    color: #fff;
    content: '●';
    display: inline-block;
    font-size: 7px;
    position: relative;
    top: -3px;
    font-family: sans-serif;
  }

.OnAirBadge__label {
    padding-right: 6.4px;
    padding-right: 0.4rem;
  }

/* news/events */

/* members area */

.MembersBar {
  padding: 3.2px 0;
  padding: 0.2rem 0;
  background-color: #b02727;
  color: #ffffff;
  text-transform: uppercase;
  font-size: 14.4px;
  font-size: 0.9rem;
}

.MembersBar__inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

.MembersBar__title {
    margin-right: 32px;
    margin-right: 2rem;
  }

.MembersBar__menu {
    list-style: none;
    margin: 0;
    padding: 0;
  }

.MembersBar__menu > li {
      display: inline-block;
    }

.MembersBar__menu-item {
    margin-right: 16px;
    margin-right: 1rem;
    color: #ffffff;
    text-decoration: none;
  }

.MembersBar__menu-item--active {
      text-decoration: underline;
    }

/* utils */

.srt {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
}

/* external bits */

.emoji-mart,
.emoji-mart * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  line-height: 1.15;
}

.emoji-mart {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif;
  font-size: 16px;
  display: inline-block;
  color: #222427;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  background: #fff;
}

.emoji-mart .emoji-mart-emoji {
  padding: 6px;
}

.emoji-mart-bar {
  border: 0 solid #d9d9d9;
}

.emoji-mart-bar:first-child {
  border-bottom-width: 1px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.emoji-mart-bar:last-child {
  border-top-width: 1px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.emoji-mart-anchors {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 6px;
  line-height: 0;
}

.emoji-mart-anchor {
  position: relative;
  display: block;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  color: #858585;
  text-align: center;
  padding: 12px 4px;
  overflow: hidden;
  -webkit-transition: color .1s ease-out;
  transition: color .1s ease-out;
  margin: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  background: none;
  border: none;
}

.emoji-mart-anchor:focus { outline: 0 }

.emoji-mart-anchor:hover,
.emoji-mart-anchor:focus,
.emoji-mart-anchor-selected {
  color: #464646;
}

.emoji-mart-anchor-selected .emoji-mart-anchor-bar {
  bottom: 0;
}

.emoji-mart-anchor-bar {
  position: absolute;
  bottom: -3px; left: 0;
  width: 100%; height: 3px;
  background-color: #464646;
}

.emoji-mart-anchors i {
  display: inline-block;
  width: 100%;
  max-width: 22px;
}

.emoji-mart-anchors svg,
.emoji-mart-anchors img {
  fill: currentColor;
  height: 18px;
  width: 18px;
}

.emoji-mart-scroll {
  overflow-y: scroll;
  height: 270px;
  padding: 0 6px 6px 6px;
  will-change: transform; /* avoids "repaints on scroll" in mobile Chrome */
}

.emoji-mart-search {
  margin-top: 6px;
  padding: 0 6px;
  position: relative;
}

.emoji-mart-search input {
  font-size: 16px;
  display: block;
  width: 100%;
  padding: 5px 25px 6px 10px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  outline: 0;
}

.emoji-mart-search input,
.emoji-mart-search input::-webkit-search-decoration,
.emoji-mart-search input::-webkit-search-cancel-button,
.emoji-mart-search input::-webkit-search-results-button,
.emoji-mart-search input::-webkit-search-results-decoration {
  /* remove webkit/blink styles for <input type="search">
   * via https://stackoverflow.com/a/9422689 */
  -webkit-appearance: none;
}

.emoji-mart-search-icon {
  position: absolute;
  top: 7px;
  right: 11px;
  z-index: 2;
  padding: 2px 5px 1px;
  border: none;
  background: none;
}

.emoji-mart-category .emoji-mart-emoji span {
  z-index: 1;
  position: relative;
  text-align: center;
  cursor: default;
}

.emoji-mart-category .emoji-mart-emoji:hover:before {
  z-index: 0;
  content: "";
  position: absolute;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background-color: #f4f4f4;
  border-radius: 100%;
}

.emoji-mart-category-label {
  z-index: 2;
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.emoji-mart-category-label span {
  display: block;
  width: 100%;
  font-weight: 500;
  padding: 5px 6px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, .95);
}

.emoji-mart-category-list {
  margin: 0;
  padding: 0;
}

.emoji-mart-category-list li {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.emoji-mart-emoji {
  position: relative;
  display: inline-block;
  font-size: 0;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.emoji-mart-emoji-native {
  font-family: "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI", "Apple Color Emoji", "Twemoji Mozilla", "Noto Color Emoji", "EmojiOne Color", "Android Emoji";
}

.emoji-mart-no-results {
  font-size: 14px;
  text-align: center;
  padding-top: 70px;
  color: #858585;
}

.emoji-mart-no-results-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.emoji-mart-no-results .emoji-mart-category-label {
  display: none;
}

.emoji-mart-no-results .emoji-mart-no-results-label {
  margin-top: .2em;
}

.emoji-mart-no-results .emoji-mart-emoji:hover:before {
  content: none;
}

.emoji-mart-preview {
  position: relative;
  height: 70px;
}

.emoji-mart-preview-emoji,
.emoji-mart-preview-data,
.emoji-mart-preview-skins {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.emoji-mart-preview-emoji {
  left: 12px;
}

.emoji-mart-preview-data {
  left: 68px; right: 12px;
  word-break: break-all;
}

.emoji-mart-preview-skins {
  right: 30px;
  text-align: right;
}

.emoji-mart-preview-skins.custom {
  right: 10px;
  text-align: right;
}

.emoji-mart-preview-name {
  font-size: 14px;
}

.emoji-mart-preview-shortname {
  font-size: 12px;
  color: #888;
}

.emoji-mart-preview-shortname + .emoji-mart-preview-shortname,
.emoji-mart-preview-shortname + .emoji-mart-preview-emoticon,
.emoji-mart-preview-emoticon + .emoji-mart-preview-emoticon {
  margin-left: .5em;
}

.emoji-mart-preview-emoticon {
  font-size: 11px;
  color: #bbb;
}

.emoji-mart-title span {
  display: inline-block;
  vertical-align: middle;
}

.emoji-mart-title .emoji-mart-emoji {
  padding: 0;
}

.emoji-mart-title-label {
  color: #999A9C;
  font-size: 26px;
  font-weight: 300;
}

.emoji-mart-skin-swatches {
  font-size: 0;
  padding: 2px 0;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  background-color: #fff;
}

.emoji-mart-skin-swatches.custom {
  font-size: 0;
  border: none;
  background-color: #fff;
}

.emoji-mart-skin-swatches.opened .emoji-mart-skin-swatch {
  width: 16px;
  padding: 0 2px;
}

.emoji-mart-skin-swatches.opened .emoji-mart-skin-swatch.selected:after {
  opacity: .75;
}

.emoji-mart-skin-swatch {
  display: inline-block;
  width: 0;
  vertical-align: middle;
  -webkit-transition-property: width, padding;
  transition-property: width, padding;
  -webkit-transition-duration: .125s;
          transition-duration: .125s;
  -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
}

.emoji-mart-skin-swatch:nth-child(1) { -webkit-transition-delay: 0s; transition-delay: 0s }

.emoji-mart-skin-swatch:nth-child(2) { -webkit-transition-delay: .03s; transition-delay: .03s }

.emoji-mart-skin-swatch:nth-child(3) { -webkit-transition-delay: .06s; transition-delay: .06s }

.emoji-mart-skin-swatch:nth-child(4) { -webkit-transition-delay: .09s; transition-delay: .09s }

.emoji-mart-skin-swatch:nth-child(5) { -webkit-transition-delay: .12s; transition-delay: .12s }

.emoji-mart-skin-swatch:nth-child(6) { -webkit-transition-delay: .15s; transition-delay: .15s }

.emoji-mart-skin-swatch.selected {
  position: relative;
  width: 16px;
  padding: 0 2px;
}

.emoji-mart-skin-swatch.selected:after {
  content: "";
  position: absolute;
  top: 50%; left: 50%;
  width: 4px; height: 4px;
  margin: -2px 0 0 -2px;
  background-color: #fff;
  border-radius: 100%;
  pointer-events: none;
  opacity: 0;
  -webkit-transition: opacity .2s ease-out;
  transition: opacity .2s ease-out;
}

.emoji-mart-skin-swatch.custom {
  display: inline-block;
  width: 0;
  height: 38px;
  overflow: hidden;
  vertical-align: middle;
  -webkit-transition-property: width, height;
  transition-property: width, height;
  -webkit-transition-duration: .125s;
          transition-duration: .125s;
  -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
  cursor: default;
}

.emoji-mart-skin-swatch.custom.selected {
  position: relative;
  width: 36px;
  height: 38px;
  padding: 0 2px 0 0;
}

.emoji-mart-skin-swatch.custom.selected:after {
  content: "";
  width: 0;
  height: 0;
}

.emoji-mart-skin-swatches.custom .emoji-mart-skin-swatch.custom:hover {
  background-color: #f4f4f4;
  border-radius: 10%;
}

.emoji-mart-skin-swatches.custom.opened .emoji-mart-skin-swatch.custom {
  width: 36px;
  height: 38px;
  padding: 0 2px 0 0;
}

.emoji-mart-skin-swatches.custom.opened .emoji-mart-skin-swatch.custom.selected:after {
  opacity: .75;
}

.emoji-mart-skin-text.opened {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  color: #888;
  font-size: 11px;
  padding: 5px 2px;
  width: 95px;
  height: 40px;
  border-radius: 10%;
  background-color: #fff;
}

.emoji-mart-skin {
  display: inline-block;
  width: 100%;
  padding-top: 100%;
  max-width: 12px;
  border-radius: 100%;
}

.emoji-mart-skin-tone-1 { background-color: #ffc93a }

.emoji-mart-skin-tone-2 { background-color: #fadcbc }

.emoji-mart-skin-tone-3 { background-color: #e0bb95 }

.emoji-mart-skin-tone-4 { background-color: #bf8f68 }

.emoji-mart-skin-tone-5 { background-color: #9b643d }

.emoji-mart-skin-tone-6 { background-color: #594539 }

/* For screenreaders only, via https://stackoverflow.com/a/19758620 */

.emoji-mart-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/*
 * Dark mode styles
 */

.emoji-mart-dark {
  color: #fff;
  border-color: #555453;
  background-color: #222;
}

.emoji-mart-dark .emoji-mart-bar {
  border-color: #555453;
}

.emoji-mart-dark .emoji-mart-search input {
  color: #fff;
  border-color: #555453;
  background-color: #2f2f2f;
}

.emoji-mart-dark .emoji-mart-search-icon svg {
  fill: #fff;
}

.emoji-mart-dark .emoji-mart-category .emoji-mart-emoji:hover:before {
  background-color: #444;
}

.emoji-mart-dark .emoji-mart-category-label span {
  background-color: #222;
  color: #fff;
}

.emoji-mart-dark .emoji-mart-skin-swatches {
  border-color: #555453;
  background-color: #222;
}

.emoji-mart-dark .emoji-mart-anchor:hover,
.emoji-mart-dark .emoji-mart-anchor:focus,
.emoji-mart-dark .emoji-mart-anchor-selected {
  color: #bfbfbf;
}
